import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 3264.000000 2448.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,2448.000000) scale(0.100000,-0.100000)">

<path d="M16009 18880 c-114 -44 -172 -82 -254 -166 -93 -96 -162 -225 -185
-345 -15 -77 -12 -216 5 -284 36 -141 143 -295 262 -377 45 -31 163 -91 198
-101 11 -3 46 -13 78 -22 l57 -16 0 -69 0 -68 -72 -6 c-40 -3 -113 -8 -163
-11 -97 -7 -268 -33 -407 -64 -73 -16 -98 -27 -150 -66 -35 -26 -89 -60 -121
-77 -101 -50 -285 -88 -275 -56 3 7 41 19 85 28 301 58 499 316 479 625 -9
157 -68 295 -166 396 -72 73 -134 112 -234 146 -69 23 -98 27 -196 27 -96 1
-127 -3 -185 -23 -116 -40 -196 -91 -274 -176 -103 -114 -151 -236 -151 -390
0 -115 18 -195 66 -285 84 -161 214 -267 380 -311 46 -12 89 -27 94 -34 7 -9
-23 -28 -115 -73 -69 -34 -161 -84 -205 -113 l-80 -51 0 -55 c0 -41 3 -52 13
-47 6 5 44 29 82 55 65 44 293 169 308 169 4 0 7 -26 7 -58 l0 -59 -123 -70
c-68 -39 -169 -100 -225 -137 -89 -57 -104 -72 -128 -118 -60 -121 -164 -221
-289 -279 -33 -15 -76 -43 -95 -63 l-34 -36 72 25 c39 14 78 25 86 25 7 0 72
57 142 128 71 70 165 153 209 184 105 74 365 231 372 224 3 -3 9 -123 14 -268
9 -281 19 -347 65 -426 33 -55 67 -80 334 -232 124 -71 231 -133 238 -137 8
-5 19 -51 27 -120 15 -122 34 -170 87 -220 37 -35 184 -127 281 -174 l64 -32
6 -76 c20 -253 15 -2977 -6 -2997 -3 -4 -38 12 -76 35 -38 22 -75 41 -83 42
-7 0 -47 22 -88 49 -41 27 -127 77 -190 111 -63 34 -275 155 -470 269 -195
114 -395 228 -444 255 -97 52 -168 111 -193 159 -37 71 -43 126 -43 395 l0
263 25 30 c30 35 33 93 8 141 -9 18 -18 41 -19 52 -2 11 -6 210 -10 441 l-7
422 59 11 c188 37 328 117 437 251 167 204 206 519 94 763 -114 249 -401 421
-676 405 -94 -6 -225 -45 -328 -97 -68 -34 -248 -212 -282 -278 -80 -160 -100
-400 -45 -563 39 -115 161 -291 243 -349 58 -41 216 -109 294 -126 l77 -17 0
-251 c0 -138 -3 -248 -6 -245 -13 14 -45 192 -55 308 -9 115 -16 139 -41 140
-29 0 17 -340 67 -498 l35 -111 -2 -193 -3 -193 -27 29 c-14 16 -41 62 -60
102 -32 71 -49 86 -56 50 -6 -30 55 -144 102 -190 l46 -44 0 -206 c0 -113 -2
-206 -5 -206 -3 0 -25 9 -48 20 -45 22 -29 6 22 -22 25 -14 31 -23 31 -52 0
-59 28 -185 52 -233 48 -97 70 -111 628 -430 140 -80 332 -192 425 -248 94
-57 195 -115 225 -130 l55 -27 -3 -476 c-2 -263 0 -511 5 -552 9 -84 19 265
22 743 0 141 3 257 5 257 2 0 47 -25 101 -55 53 -30 99 -55 101 -55 2 0 29
-16 59 -36 l54 -36 3 -572 c3 -624 1 -598 62 -655 l26 -24 0 601 c0 331 4 602
8 602 4 0 23 -12 41 -27 l33 -28 -2 -642 -1 -643 66 0 65 0 0 920 0 920 39 19
c21 11 41 17 45 14 3 -4 6 -396 6 -871 l0 -865 26 24 c58 53 56 19 55 931 l-2
838 83 53 c86 55 231 137 242 137 4 0 6 -359 5 -798 -2 -578 1 -803 9 -818 6
-10 12 -17 14 -14 1 3 4 268 7 590 l6 585 225 131 c348 204 363 225 367 513
l2 174 116 67 c63 37 123 74 133 82 18 16 124 77 457 262 116 64 226 126 246
139 248 153 270 170 310 249 35 68 49 162 49 331 0 153 1 157 21 157 47 0 210
55 279 94 50 28 119 83 163 131 59 64 95 126 143 247 43 111 44 114 44 234 0
109 -3 128 -30 204 -38 102 -39 104 -101 188 -27 37 -57 78 -67 92 -46 67
-152 145 -243 179 -73 28 -200 44 -289 36 -202 -17 -240 -25 -326 -73 -46 -25
-105 -65 -131 -89 -26 -24 -42 -42 -35 -38 21 11 13 -3 -32 -59 -38 -47 -60
-86 -115 -206 l-11 -25 5 27 c3 14 7 34 11 45 3 10 -2 5 -11 -10 -28 -49 -55
-176 -55 -261 0 -201 98 -424 241 -549 74 -65 207 -130 311 -152 70 -16 88
-23 92 -40 10 -36 6 -249 -5 -311 -20 -108 -74 -181 -159 -214 -19 -7 -46 -23
-60 -35 -27 -24 -692 -405 -707 -405 -5 0 -18 -8 -29 -18 -11 -11 -63 -41
-117 -69 l-97 -50 0 96 c0 53 -3 132 -6 176 -6 67 -4 84 10 97 8 9 64 27 122
41 59 14 109 29 113 35 3 5 20 16 36 25 120 61 236 221 266 366 6 32 9 101 7
161 -4 89 -10 116 -36 180 -68 161 -188 268 -377 336 -36 13 -80 18 -160 18
-95 0 -119 -4 -178 -27 -330 -129 -483 -512 -325 -810 84 -157 265 -280 446
-303 l52 -7 0 -206 0 -206 -283 -161 c-155 -89 -283 -160 -284 -159 -2 1 -3
415 -3 919 l0 918 42 27 c24 15 98 58 166 96 148 83 221 146 250 213 24 55 42
181 42 289 0 40 3 73 7 73 4 0 31 -10 59 -21 48 -20 52 -25 58 -62 4 -22 4
-144 0 -272 -5 -154 -4 -238 3 -250 8 -13 35 -23 92 -35 l81 -16 0 23 c0 22
-3 23 -34 18 -23 -5 -36 -3 -42 6 -4 8 -7 147 -5 310 l2 296 -28 18 c-15 10
-64 33 -108 51 l-80 33 -3 66 c-2 36 1 65 5 65 4 0 38 7 74 14 82 18 131 42
189 95 25 22 52 43 60 46 20 7 72 86 112 170 32 68 33 74 33 195 0 119 -2 129
-33 201 -41 95 -67 130 -145 198 -107 92 -224 129 -381 118 -137 -9 -279 -79
-353 -176 -62 -81 -64 -79 -61 77 l3 137 30 3 c85 8 175 52 243 116 147 140
164 383 39 553 -75 101 -172 152 -302 161 -136 8 -228 -23 -317 -106 -62 -57
-96 -115 -120 -201 -23 -83 -23 -139 0 -223 41 -147 135 -239 295 -289 l97
-31 0 -172 c0 -120 -4 -189 -15 -229 -19 -75 -19 -165 1 -263 12 -58 14 -101
9 -168 -10 -128 -10 -196 -1 -231 l7 -30 -78 0 c-101 -1 -169 -24 -231 -80
-68 -61 -95 -126 -96 -231 -1 -71 3 -88 29 -139 38 -74 109 -133 203 -169 78
-30 82 -36 82 -126 0 -116 -4 -122 -91 -175 -98 -58 -132 -86 -168 -139 -62
-90 -63 -102 -65 -734 l-1 -575 -34 -18 c-19 -10 -38 -16 -43 -13 -9 6 -11
3800 -2 3893 6 50 10 61 25 61 13 0 19 7 19 24 0 13 3 31 6 39 4 11 -4 17 -26
22 -30 7 -31 8 -28 58 l3 52 50 -1 c48 -2 51 0 81 41 l32 42 -75 6 c-41 3 -76
7 -78 9 -1 2 -5 29 -8 61 -5 53 -4 59 20 74 13 9 67 29 120 44 121 35 189 76
283 169 126 125 184 272 184 460 -1 125 -18 200 -76 319 -54 113 -160 220
-268 273 -158 77 -202 88 -340 87 -119 -1 -129 -3 -221 -39z m392 -300 c27
-15 27 -92 0 -119 -11 -11 -24 -35 -29 -55 -9 -32 -8 -35 11 -38 19 -3 22 -11
25 -56 3 -48 0 -55 -30 -82 -18 -17 -43 -30 -55 -30 -23 0 -23 -1 -23 -139 l0
-140 -44 -6 c-25 -4 -57 -3 -73 2 l-28 10 -3 132 c-2 128 -4 133 -28 156 -14
13 -31 26 -37 28 -19 6 4 118 28 131 37 21 45 35 45 76 0 49 22 95 53 112 47
25 154 35 188 18z m339 -55 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23
6 23 -9z m35 -75 c3 -5 3 -15 -1 -21 -6 -11 -23 6 -24 24 0 10 18 9 25 -3z
m-1600 -435 c50 -3 50 -3 49 -36 -2 -43 -54 -211 -83 -267 -30 -59 -98 -125
-163 -158 -91 -47 -255 -45 -310 4 -27 23 -22 29 30 41 26 6 60 17 75 23 l28
11 -43 19 c-48 21 -58 33 -58 67 0 13 -11 36 -25 53 -28 33 -34 78 -10 78 8 0
15 5 15 10 0 6 -4 10 -8 10 -15 0 -32 66 -26 98 8 41 26 41 80 -3 54 -44 119
-74 161 -75 29 0 34 5 54 50 32 75 61 94 129 85 30 -4 78 -8 105 -10z m995
-730 c0 -53 0 -54 -32 -59 -18 -3 -80 -7 -138 -10 -128 -7 -210 -17 -209 -25
0 -3 23 -21 51 -39 50 -32 52 -32 142 -27 50 3 112 8 139 11 l47 5 0 -1016 c0
-558 -3 -1015 -7 -1015 -5 0 -23 9 -40 20 -30 18 -33 24 -33 67 0 102 -21 225
-46 279 -40 84 -125 148 -386 289 l-88 47 -2 179 c-1 98 -2 202 -3 231 l0 51
81 18 c173 37 281 122 342 271 22 53 26 79 26 163 1 120 -19 191 -79 279 -83
124 -195 186 -350 194 -287 14 -485 -174 -485 -460 1 -246 156 -422 401 -454
l49 -7 -2 -215 c-2 -132 -7 -217 -13 -219 -6 -2 -53 21 -105 51 -52 29 -149
83 -215 121 -139 78 -167 102 -203 170 l-27 50 -3 316 -4 316 44 16 c36 14 48
26 74 77 17 33 28 60 23 60 -5 0 -34 -11 -65 -25 -31 -14 -60 -25 -65 -25 -5
0 -9 25 -9 55 l0 56 48 20 c299 129 740 224 1105 238 l37 1 0 -55z m715 11
c59 -25 86 -97 65 -176 -20 -74 -84 -110 -141 -80 -16 9 -19 7 -19 -8 0 -39
-41 -114 -55 -100 -4 4 13 224 21 261 5 25 10 28 37 25 l32 -3 -4 -67 c-3 -58
-1 -68 13 -68 32 0 66 46 66 88 0 87 -82 123 -150 65 -32 -26 -32 -27 -25 -91
7 -63 6 -63 -15 -52 -46 25 -50 91 -10 158 35 56 114 77 185 48z m-1163 -323
c8 -10 19 -31 22 -48 9 -37 9 -232 0 -291 l-6 -44 -43 0 c-23 0 -47 6 -53 13
-17 20 -19 352 -3 371 18 21 64 20 83 -1z m-148 -130 c3 -27 7 -93 8 -148 l3
-100 -47 -3 c-40 -3 -47 0 -53 18 -12 36 -8 205 4 243 11 33 16 37 46 37 32 0
33 -1 39 -47z m-169 -69 c49 -19 57 -147 12 -179 -30 -21 -64 -19 -77 4 -18
35 -12 125 11 155 22 27 28 30 54 20z m347 -212 c7 -7 9 -15 5 -19 -16 -15
-140 -22 -284 -16 -139 5 -153 8 -153 24 0 17 12 19 118 22 176 6 302 1 314
-11z m1512 -266 c47 -19 51 -32 24 -65 -18 -21 -29 -26 -43 -20 -37 14 -112
10 -126 -7 -7 -9 -15 -42 -17 -73 -4 -53 -2 -59 23 -79 31 -25 54 -26 104 -8
48 17 52 36 8 36 -43 0 -59 18 -51 59 l7 31 89 0 90 0 -4 -64 c-6 -105 -71
-166 -175 -166 -135 0 -222 148 -163 278 33 73 151 113 234 78z m264 -98 c2
-20 10 -28 33 -33 23 -5 29 -12 29 -34 0 -25 -3 -27 -29 -23 -27 4 -30 1 -33
-29 -2 -29 -7 -34 -28 -34 -20 0 -26 6 -28 27 -3 24 -6 26 -38 21 l-34 -6 0
42 c0 37 2 41 25 41 17 0 29 9 40 31 21 39 58 38 63 -3z m-468 -525 c51 -24
152 -53 187 -53 2 0 3 -20 3 -45 0 -25 -4 -45 -10 -45 -13 0 -303 106 -332
121 -33 17 -39 30 -33 69 5 29 2 40 -19 60 -18 16 -26 34 -26 56 l0 33 83 -82
c57 -58 102 -92 147 -114z m-2551 57 c134 -15 148 -22 160 -81 14 -64 14 -283
0 -348 -6 -28 -17 -52 -26 -55 -68 -26 -548 -33 -628 -9 -51 16 -59 48 -59
233 -1 91 2 177 7 192 13 48 49 62 172 69 185 9 286 9 374 -1z m1124 -46 c108
-55 243 -138 280 -173 63 -59 80 -102 85 -214 l4 -99 -52 27 c-120 61 -269
156 -304 195 -21 23 -45 59 -53 79 -16 37 -31 211 -19 211 4 0 30 -12 59 -26z
m1260 -105 c23 -12 49 -28 58 -35 12 -11 250 -104 265 -104 2 0 10 -7 18 -16
22 -22 22 -239 0 -313 -27 -91 -102 -155 -336 -285 l-68 -38 0 94 1 93 55 35
c63 39 111 94 130 148 20 57 17 199 -5 248 -21 47 -75 102 -138 142 -23 15
-43 33 -43 40 0 17 15 15 63 -9z m1307 -29 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-1461 -89 c37 -27 61 -87 61 -155 0 -51 2
-55 60 -113 61 -61 74 -95 48 -121 -25 -25 -59 -12 -119 45 -59 56 -62 57
-132 64 -68 6 -73 9 -108 48 -42 47 -53 88 -39 142 28 101 147 148 229 90z
m2193 8 c77 -16 134 -52 161 -102 21 -41 22 -51 22 -317 0 -271 0 -276 -24
-324 -26 -52 -56 -75 -140 -107 -49 -18 -77 -20 -277 -20 -261 1 -301 10 -366
83 -60 68 -63 85 -63 358 0 235 1 247 23 297 28 60 53 86 105 108 20 8 37 20
37 25 0 14 455 13 522 -1z m-715 -251 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7
2 -19 0 -25z m-2049 -1666 c1 -970 -1 -1532 -7 -1532 -6 0 -22 5 -36 12 l-25
11 0 1540 0 1539 33 -19 32 -19 3 -1532z m2039 1451 c-3 -10 -5 -2 -5 17 0 19
2 27 5 18 2 -10 2 -26 0 -35z m-1407 -136 l0 -93 -31 -18 c-17 -10 -33 -15
-36 -12 -3 3 -8 50 -11 105 -5 108 -5 107 56 110 21 1 22 -2 22 -92z m1407 -4
c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-1407 -1112 l0 -920
-159 -85 c-87 -47 -161 -86 -165 -86 -3 0 -6 131 -6 290 l0 290 33 30 32 30
-28 -10 c-16 -5 -30 -10 -33 -10 -2 0 -4 240 -4 533 0 580 2 603 57 681 24 32
141 116 164 116 3 0 11 -48 16 -107 l10 -108 2 115 c1 121 4 130 36 148 47 27
45 66 45 -907z m760 710 c70 -27 130 -78 165 -142 27 -48 30 -63 30 -139 0
-73 -4 -93 -28 -142 -35 -71 -116 -147 -183 -171 -62 -22 -155 -22 -201 -1
-31 15 -44 16 -119 4 -46 -7 -87 -10 -91 -6 -4 3 1 28 10 54 21 59 21 89 0
138 -27 64 -22 177 10 244 39 82 96 136 173 164 58 22 174 20 234 -3z m-3153
-673 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1982 -871 c2 -156
0 -293 -3 -305 -8 -24 -61 -56 -76 -47 -14 9 -13 560 1 586 6 12 25 27 42 34
18 8 32 14 32 15 0 0 2 -128 4 -283z m1008 -59 c-3 -91 -11 -181 -18 -203 -17
-50 -104 -137 -190 -188 -110 -66 -344 -197 -352 -197 -4 0 -6 97 -5 217 l3
216 275 158 c151 87 279 159 284 159 5 0 6 -71 3 -162z"/>
<path d="M16306 18434 c-8 -22 -8 -54 2 -54 34 1 52 14 52 41 0 24 -4 29 -24
29 -13 0 -27 -7 -30 -16z"/>
<path d="M16300 18221 c0 -5 9 -7 20 -4 11 3 20 7 20 9 0 2 -9 4 -20 4 -11 0
-20 -4 -20 -9z"/>
<path d="M14230 15491 c0 -50 4 -92 9 -95 5 -3 50 14 101 37 l92 44 -27 21
c-29 23 -148 82 -165 82 -6 0 -10 -37 -10 -89z"/>
<path d="M16605 15411 c-40 -17 -58 -47 -51 -87 10 -52 46 -84 95 -84 53 0 69
7 86 41 37 70 0 140 -72 138 -21 0 -47 -4 -58 -8z"/>
<path d="M18418 15364 c-37 -11 -48 -20 -57 -47 -20 -55 -29 -215 -22 -383 9
-238 3 -234 349 -234 273 1 293 5 326 74 17 35 20 64 20 240 1 188 -9 287 -32
323 -4 7 -41 19 -81 28 -97 20 -435 20 -503 -1z m540 -65 c30 -47 -30 -108
-70 -72 -25 22 -23 59 4 77 31 22 51 20 66 -5z m-230 -51 c93 -23 144 -87 145
-182 1 -51 -4 -72 -26 -108 -33 -56 -41 -62 -101 -77 -86 -21 -159 1 -215 64
-27 31 -31 43 -31 95 0 38 8 80 23 116 20 51 27 59 72 80 57 27 69 28 133 12z"/>
<path d="M18623 15152 c-6 -4 -17 -28 -24 -54 -16 -58 -5 -86 44 -112 35 -19
35 -19 83 5 52 25 63 50 50 106 -12 45 -112 81 -153 55z"/>
<path d="M16707 14059 c-4 -97 -5 -234 -1 -305 11 -214 -13 -312 -99 -397 -23
-23 -33 -35 -22 -27 48 34 79 68 103 115 l27 50 3 300 c4 385 -1 495 -11 264z"/>
<path d="M17347 14414 c-107 -34 -176 -149 -164 -274 6 -67 25 -107 74 -156
52 -52 105 -74 184 -74 148 0 258 108 259 255 0 33 -5 76 -10 96 -14 48 -71
113 -124 138 -58 29 -157 35 -219 15z m19 -108 c14 -11 19 -27 20 -69 0 -46 6
-61 32 -93 l31 -37 56 13 c48 11 58 11 69 -1 43 -45 16 -99 -51 -99 -101 0
-243 138 -243 237 0 32 9 48 30 56 25 9 35 8 56 -7z"/>
<path d="M17190 13920 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M17266 17232 c7 -26 14 -50 14 -53 0 -3 26 -13 58 -23 31 -10 107
-38 169 -62 61 -24 114 -44 117 -44 12 0 6 88 -6 97 -13 10 -173 70 -263 98
-28 9 -62 20 -77 25 l-26 8 14 -46z"/>
<path d="M17670 16940 c0 -93 4 -170 9 -170 12 0 57 104 75 173 19 72 12 110
-26 142 -56 47 -58 43 -58 -145z"/>
<path d="M17271 17008 c-14 -46 -13 -49 52 -72 28 -11 47 -24 47 -33 0 -9 -22
-58 -50 -110 -27 -52 -50 -96 -50 -99 0 -3 23 -4 51 -2 l51 3 34 62 c18 35 45
90 59 124 25 58 25 62 8 75 -26 20 -110 64 -153 79 l-37 13 -12 -40z"/>
<path d="M17578 16753 c-27 -51 -48 -96 -48 -101 0 -9 53 -42 68 -42 21 0 33
59 30 146 l-3 89 -47 -92z"/>
<path d="M13695 16184 c-232 -327 -385 -670 -454 -1019 -42 -213 -61 -465 -45
-601 l6 -51 44 47 44 47 0 139 c0 262 45 501 147 773 79 213 238 494 387 685
25 33 45 60 43 61 -1 1 -25 5 -53 8 l-52 6 -67 -95z"/>
<path d="M17482 15627 l-54 -20 99 -38 c108 -41 113 -40 105 26 -3 27 -10 36
-35 44 -39 14 -51 13 -115 -12z"/>
<path d="M17679 15609 c-1 -2 -2 -24 -3 -48 -2 -34 3 -49 21 -67 l23 -23 -7
-246 -6 -246 44 6 c24 4 70 16 102 27 l57 20 0 49 0 49 -49 -20 -50 -19 -3
209 c-3 190 -5 211 -23 235 -18 24 -103 83 -106 74z"/>
<path d="M13527 15393 c-70 -241 -97 -387 -104 -571 l-6 -144 31 16 c17 9 38
16 47 16 12 0 15 12 15 58 0 81 27 287 50 387 l19 83 -20 84 c-24 94 -25 95
-32 71z"/>
<path d="M18035 15359 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M17887 14874 c-3 -3 -52 -23 -109 -44 l-103 -38 -3 -41 c-3 -36 0
-41 17 -41 18 0 239 76 249 86 2 1 1 21 -3 43 -5 30 -12 41 -24 41 -10 0 -20
-3 -24 -6z"/>
<path d="M13926 14807 c-3 -12 -6 -47 -6 -77 0 -49 3 -56 25 -67 l25 -11 0 79
c0 67 -3 80 -19 88 -15 9 -20 7 -25 -12z"/>
<path d="M13526 14679 c-63 -22 -153 -75 -200 -118 -15 -14 -53 -76 -82 -138
-54 -111 -54 -112 -54 -206 0 -93 1 -97 47 -188 71 -143 174 -235 311 -281 30
-10 70 -18 89 -18 18 0 33 -4 33 -10 0 -5 -9 -10 -21 -10 -19 0 -16 -10 35
-106 69 -130 158 -263 306 -459 572 -756 952 -1685 1061 -2593 12 -98 13 -103
38 -108 14 -3 111 -28 215 -55 250 -65 366 -86 576 -104 571 -49 1245 20 1485
153 40 22 51 34 57 62 13 56 56 310 63 370 12 98 43 273 64 363 26 107 122
400 184 557 202 512 361 789 786 1367 323 439 446 702 521 1118 10 55 17 101
16 102 -2 2 -24 -8 -51 -20 l-48 -24 -23 -114 c-74 -357 -199 -613 -494 -1013
-450 -608 -637 -945 -849 -1531 -107 -294 -153 -471 -191 -740 -28 -203 -60
-387 -71 -406 -10 -20 -118 -61 -231 -88 -367 -88 -1039 -104 -1453 -35 -68
11 -479 111 -492 119 -2 1 -12 64 -23 141 -85 586 -271 1164 -550 1713 -148
292 -309 546 -542 858 -79 105 -151 206 -162 223 l-19 32 67 -37 c36 -20 66
-34 66 -31 0 7 -158 109 -184 119 -16 6 -17 5 -6 -8 11 -13 8 -13 -19 -4 -18
6 -38 24 -46 40 -14 25 -14 28 0 23 11 -5 14 -2 9 12 -4 12 10 3 35 -21 l42
-40 -43 82 c-34 66 -48 83 -65 83 -39 0 -25 17 20 24 57 9 212 86 265 131 111
96 173 290 143 450 -22 119 -96 237 -179 286 -21 12 -46 30 -57 40 -28 25
-176 59 -255 59 -39 -1 -89 -9 -124 -21z m504 -283 c27 -61 40 -122 38 -191
l-1 -60 -7 65 c-10 81 -26 144 -51 192 -21 42 -24 52 -9 43 6 -3 19 -26 30
-49z m-147 -84 c-12 -8 -26 -79 -43 -222 -17 -147 -18 -150 -46 -150 -14 0
-42 9 -62 19 -39 20 -132 112 -132 131 0 6 34 47 76 91 42 44 74 83 70 86 -7
7 -64 -30 -125 -81 -53 -44 -112 -76 -141 -76 -25 0 -72 23 -89 44 -11 13 -10
19 9 34 36 30 318 169 395 196 l70 25 14 -45 c11 -33 12 -47 4 -52z"/>
<path d="M19276 14574 c-44 -59 -49 -70 -57 -141 -29 -262 -101 -526 -202
-744 -84 -180 -155 -290 -456 -703 -230 -314 -370 -543 -516 -841 -232 -475
-405 -1058 -445 -1505 -20 -226 -28 -253 -79 -279 -77 -40 -440 -134 -651
-170 -230 -39 -321 -46 -595 -45 -270 0 -360 8 -613 50 -188 32 -681 153 -704
173 -9 8 -33 142 -58 326 -74 538 -218 992 -482 1519 -169 337 -332 596 -549
871 -127 161 -241 340 -322 506 l-72 146 -57 26 -57 25 20 -52 c72 -185 216
-438 341 -604 45 -59 114 -150 154 -202 357 -473 692 -1166 833 -1725 55 -216
75 -328 117 -635 16 -121 28 -185 45 -236 12 -37 16 -39 274 -108 455 -122
769 -168 1140 -168 361 -1 646 41 1050 152 181 50 221 65 262 96 60 46 72 87
93 319 47 517 251 1154 538 1677 140 256 240 407 501 763 341 464 462 716 546
1129 21 103 60 435 53 441 -2 2 -25 -26 -52 -61z"/>
<path d="M15571 13709 c-76 -23 -152 -88 -185 -159 -15 -34 -21 -65 -21 -125
0 -95 22 -150 88 -215 46 -47 78 -66 148 -86 l49 -15 0 -169 c0 -102 4 -171
10 -175 6 -4 10 26 10 83 0 49 3 130 6 180 l7 90 35 7 c57 11 145 60 185 104
48 54 72 133 64 218 -16 167 -117 263 -282 269 -44 2 -95 -1 -114 -7z m174
-155 c8 -10 18 -36 21 -56 4 -22 12 -38 20 -38 19 0 18 -29 -1 -36 -27 -11
-17 -44 20 -65 19 -12 35 -23 35 -25 0 -8 -113 -54 -148 -60 -39 -6 -102 10
-164 42 l-30 16 36 30 c38 31 45 52 21 62 -21 8 -19 24 5 31 15 5 20 15 20 41
0 24 8 43 26 61 22 22 32 25 75 21 33 -4 55 -12 64 -24z"/>
<path d="M15662 12628 c1 -95 5 -83 10 30 2 34 0 62 -4 62 -5 0 -7 -42 -6 -92z"/>
<path d="M14906 10109 c-20 -17 -40 -47 -46 -67 -6 -23 -10 -241 -10 -577 0
-518 1 -541 20 -572 40 -66 117 -100 360 -159 212 -51 268 -63 445 -90 472
-72 976 -51 1472 61 362 81 447 115 480 193 27 64 45 883 22 1037 -18 131 -69
195 -155 195 -23 0 -79 -11 -125 -25 -312 -93 -528 -135 -820 -161 -453 -39
-947 17 -1376 156 -158 51 -217 53 -267 9z m249 -104 c224 -72 522 -132 772
-154 151 -14 500 -14 653 -1 268 23 517 72 783 156 43 13 96 24 118 24 37 0
41 -3 55 -37 28 -65 34 -249 24 -638 -6 -203 -15 -386 -20 -406 -13 -46 -40
-59 -233 -108 -414 -105 -682 -141 -1057 -141 -307 0 -558 27 -839 91 -223 51
-260 59 -311 75 -77 23 -150 64 -156 88 -2 12 -3 253 -2 536 3 550 4 560 52
560 12 0 85 -20 161 -45z"/>
<path d="M15080 9455 l0 -453 33 -11 c350 -109 798 -181 1135 -181 388 0 919
80 1115 167 l57 26 0 440 0 439 -55 -7 c-31 -3 -120 -20 -198 -37 -78 -16
-183 -38 -233 -49 -329 -69 -838 -77 -1217 -18 -155 23 -372 68 -524 108
l-113 29 0 -453z"/>
<path d="M15442 8518 c-49 -49 53 -226 201 -348 75 -62 253 -151 362 -181 122
-34 358 -39 478 -10 249 59 446 198 540 382 68 131 45 166 -101 155 -76 -5
-258 -27 -347 -41 -16 -3 -100 -8 -186 -12 -174 -7 -346 5 -617 43 -190 26
-311 31 -330 12z m583 -198 c90 -6 286 -7 446 -3 327 7 332 6 259 -60 -130
-115 -301 -170 -505 -162 -117 4 -233 32 -323 77 -64 32 -149 105 -172 147
l-12 24 73 -7 c41 -3 146 -11 234 -16z"/>
<path d="M10812 7700 c-145 -20 -250 -67 -313 -138 -54 -62 -74 -127 -73 -242
0 -80 4 -104 26 -151 46 -101 129 -164 264 -200 105 -28 381 -31 488 -5 199
48 297 149 312 321 25 273 -140 410 -506 420 -74 2 -163 0 -198 -5z m394 -207
c51 -26 75 -70 81 -146 12 -164 -68 -218 -323 -218 -196 0 -281 35 -305 124
-14 51 -7 143 14 184 36 69 94 83 328 79 144 -2 171 -5 205 -23z"/>
<path d="M11704 7700 c-12 -4 -31 -21 -43 -36 -21 -26 -21 -34 -21 -370 l0
-344 100 0 100 0 0 221 c0 126 4 219 9 217 5 -1 112 -96 238 -209 126 -113
241 -214 256 -224 43 -28 104 -15 144 29 l33 36 0 340 0 340 -100 0 -100 0 -2
-222 -3 -221 -219 194 c-294 261 -292 259 -335 258 -20 0 -46 -4 -57 -9z"/>
<path d="M13545 7700 c-11 -4 -34 -23 -51 -41 -26 -29 -433 -596 -488 -681
l-18 -28 133 0 134 0 170 241 c94 132 175 245 181 250 7 6 43 -40 112 -144
l102 -152 -159 -3 -159 -2 -67 -91 c-36 -50 -64 -93 -62 -95 2 -2 196 -3 431
-2 l426 3 -234 339 c-247 356 -265 380 -308 402 -29 15 -111 18 -143 4z"/>
<path d="M20630 7699 c-43 -18 -68 -48 -243 -294 -94 -132 -204 -286 -244
-342 -40 -56 -73 -105 -73 -108 0 -3 59 -4 132 -3 l132 3 173 245 c96 135 177
246 181 247 4 2 53 -66 109 -150 l103 -152 -160 -3 -159 -2 -65 -90 c-36 -49
-66 -92 -66 -95 0 -3 192 -5 426 -5 337 0 425 3 421 13 -15 38 -486 702 -510
719 -34 25 -118 34 -157 17z"/>
<path d="M22425 7696 c-16 -7 -42 -26 -56 -42 -27 -30 -499 -691 -499 -699 0
-3 58 -5 130 -5 l129 0 20 28 c11 15 90 126 176 247 86 121 159 221 163 223 4
1 53 -67 109 -150 l102 -153 -159 -3 -158 -2 -66 -90 c-36 -50 -66 -93 -66
-95 0 -3 192 -5 426 -5 337 0 425 3 421 13 -15 38 -480 694 -507 715 -39 31
-119 40 -165 18z"/>
<path d="M9561 7611 c-39 -49 -71 -92 -71 -95 0 -3 76 -6 170 -6 l170 0 2
-277 3 -278 113 -3 112 -3 0 281 0 280 135 0 135 0 67 86 c38 47 70 90 71 95
2 5 -171 9 -416 9 l-420 0 -71 -89z"/>
<path d="M12670 7325 l0 -375 115 0 115 0 0 375 0 375 -115 0 -115 0 0 -375z"/>
<path d="M14320 7598 c0 -57 -1 -224 -3 -373 l-2 -270 331 -3 331 -2 73 92 72
93 -286 3 -286 2 -2 278 -3 277 -112 3 -113 3 0 -103z"/>
<path d="M15210 7325 l0 -375 115 0 115 0 0 375 0 375 -115 0 -115 0 0 -375z"/>
<path d="M15863 7685 c-112 -30 -190 -84 -244 -167 -115 -179 -37 -421 165
-514 100 -46 169 -54 469 -54 l277 0 0 235 0 235 -239 0 -239 0 -65 -77 -65
-78 189 -3 189 -2 0 -60 0 -60 -157 0 c-178 1 -218 7 -271 44 -50 34 -74 78
-75 135 -1 69 35 128 98 160 48 25 55 26 278 29 l228 3 69 87 c38 48 70 90 70
95 0 4 -141 7 -312 6 -237 0 -326 -4 -365 -14z"/>
<path d="M16660 7325 l0 -375 115 0 114 0 3 143 3 142 228 3 227 2 0 -145 0
-145 115 0 115 0 0 375 0 375 -115 0 -114 0 -3 -132 -3 -133 -225 0 -225 0 -3
133 -3 132 -114 0 -115 0 0 -375z"/>
<path d="M17724 7607 l-71 -92 173 -3 174 -2 0 -280 0 -280 115 0 115 0 2 278
3 277 132 3 133 3 70 87 c38 48 70 90 70 94 0 5 -190 8 -422 7 l-423 0 -71
-92z"/>
<path d="M19232 7610 c-40 -49 -72 -92 -72 -95 0 -3 125 -5 278 -5 257 0 280
-2 322 -21 57 -26 77 -44 102 -93 15 -29 19 -52 15 -89 -5 -56 -27 -90 -86
-132 l-43 -30 -184 -3 -184 -4 0 136 0 136 -52 1 c-29 0 -75 2 -103 3 -27 2
-56 1 -62 -2 -10 -3 -13 -59 -13 -234 l0 -230 303 4 c335 5 373 11 476 78 109
69 162 157 169 281 7 116 -15 177 -88 251 -63 62 -125 96 -228 123 -45 11
-113 15 -269 15 l-209 0 -72 -90z"/>
<path d="M21163 7607 l-73 -92 175 -3 175 -2 0 -280 0 -280 115 0 115 0 0 280
0 280 133 0 132 0 62 79 c35 44 66 86 69 95 6 15 -33 16 -412 16 l-419 -1 -72
-92z"/>
<path d="M16217 6700 c-150 -58 -224 -197 -215 -404 8 -169 67 -274 186 -333
50 -25 68 -28 152 -28 75 0 104 4 141 21 90 42 157 125 174 217 l7 37 -76 0
c-64 0 -77 -3 -82 -17 -30 -98 -112 -148 -204 -123 -106 29 -162 155 -139 315
20 147 105 222 220 196 57 -13 90 -39 114 -89 l19 -42 73 0 c70 0 73 1 73 23
0 13 -14 52 -31 86 -36 72 -96 122 -173 146 -68 21 -179 19 -239 -5z"/>
<path d="M12594 6696 c-3 -7 -4 -38 -2 -67 l3 -54 73 -3 72 -3 0 71 0 70 -70
0 c-51 0 -72 -4 -76 -14z"/>
<path d="M13794 6696 c-3 -7 -4 -38 -2 -67 l3 -54 73 -3 72 -3 0 71 0 70 -70
0 c-51 0 -72 -4 -76 -14z"/>
<path d="M10960 6427 c0 -320 6 -353 74 -421 57 -58 140 -81 261 -74 144 9
219 64 250 183 12 46 15 118 15 323 l0 263 -77 -3 -78 -3 -5 -282 -5 -283 -26
-26 c-49 -48 -165 -50 -215 -3 -13 12 -28 39 -34 58 -5 20 -10 150 -10 289 l0
252 -75 0 -75 0 0 -273z"/>
<path d="M12310 6325 l0 -375 75 0 75 0 0 375 0 375 -75 0 -75 0 0 -375z"/>
<path d="M15420 6569 l0 -131 -42 36 c-90 77 -225 55 -295 -48 -44 -65 -56
-115 -51 -218 5 -109 40 -186 106 -233 40 -27 51 -30 122 -30 73 0 81 2 118
32 l41 33 3 -28 c3 -26 5 -27 71 -30 l67 -3 0 376 0 375 -70 0 -70 0 0 -131z
m-40 -207 c41 -41 55 -102 41 -177 -13 -72 -36 -106 -84 -122 -85 -29 -148 26
-155 134 -6 86 4 126 37 162 25 27 36 31 81 31 43 0 56 -5 80 -28z"/>
<path d="M16770 6325 l0 -375 70 0 70 0 0 180 c0 200 6 227 60 255 54 28 118
13 139 -32 7 -15 11 -96 11 -213 l0 -190 70 0 70 0 0 223 c0 211 -1 224 -23
262 -54 96 -213 115 -295 34 l-32 -31 0 131 0 131 -70 0 -70 0 0 -375z"/>
<path d="M19520 6325 l0 -377 203 4 c181 3 207 6 249 25 63 29 122 93 150 161
20 50 22 75 22 202 0 126 -2 151 -20 190 -30 65 -69 107 -128 138 -50 27 -55
27 -263 30 l-213 3 0 -376z m390 226 c23 -12 44 -36 60 -67 22 -43 25 -61 25
-154 0 -93 -3 -111 -26 -155 -39 -77 -80 -96 -199 -93 l-95 3 -3 230 c-1 126
0 235 2 242 9 22 193 17 236 -6z"/>
<path d="M14120 6585 l0 -75 -40 0 -40 0 0 -55 0 -55 40 0 40 0 0 -196 0 -196
28 -27 c30 -31 106 -47 167 -35 34 6 35 8 35 53 l0 47 -42 10 -43 9 -3 168 -2
167 45 0 45 0 0 55 0 55 -45 0 -45 0 0 75 0 75 -70 0 -70 0 0 -75z"/>
<path d="M20890 6585 l0 -75 -40 0 -40 0 0 -55 0 -55 40 0 40 0 0 -196 0 -196
28 -27 c30 -31 106 -47 167 -35 34 6 35 8 35 53 l0 47 -42 10 -43 9 -3 168 -2
167 45 0 45 0 0 55 0 55 -45 0 -45 0 0 75 0 75 -70 0 -70 0 0 -75z"/>
<path d="M11914 6501 c-22 -10 -50 -31 -62 -45 l-21 -27 -3 38 -3 38 -67 3
-68 3 0 -281 0 -280 70 0 70 0 0 170 c0 197 10 238 65 264 44 21 88 20 116 -3
23 -19 24 -24 29 -223 l5 -203 73 -3 72 -3 0 203 c0 112 -5 220 -11 241 -29
107 -155 158 -265 108z"/>
<path d="M13095 6506 c-17 -7 -43 -27 -57 -45 -15 -17 -29 -31 -32 -31 -3 0
-6 18 -6 40 l0 40 -65 0 -65 0 0 -280 0 -280 70 0 70 0 0 180 c0 192 9 238 48
259 35 19 81 13 107 -14 25 -24 25 -25 25 -225 l0 -200 75 0 75 0 0 196 0 196
29 29 c52 51 121 32 141 -39 5 -20 10 -115 10 -209 l0 -173 70 0 70 0 0 210
c0 168 -3 218 -16 248 -31 75 -89 112 -176 112 -51 0 -85 -15 -120 -52 l-29
-32 -22 26 c-46 55 -134 74 -202 44z"/>
<path d="M14589 6505 c-60 -19 -123 -82 -143 -142 -21 -61 -21 -198 0 -260 36
-109 115 -163 238 -163 38 0 86 5 107 12 67 20 149 104 149 153 0 12 -13 15
-67 15 -62 0 -68 -2 -92 -31 -21 -24 -36 -32 -71 -35 -71 -7 -120 31 -135 104
l-7 32 191 0 191 0 0 47 c0 113 -52 213 -133 255 -54 27 -164 34 -228 13z
m159 -121 c27 -19 55 -71 48 -90 -4 -11 -29 -14 -110 -14 -58 0 -107 4 -110 8
-9 15 31 88 55 99 35 17 91 15 117 -3z"/>
<path d="M17499 6505 c-62 -20 -123 -82 -145 -147 -25 -77 -16 -227 18 -293
31 -60 49 -77 108 -104 52 -24 159 -28 221 -9 67 20 149 104 149 153 0 12 -13
15 -67 15 -62 0 -68 -2 -92 -31 -21 -24 -36 -32 -71 -35 -71 -7 -120 31 -135
104 l-7 32 191 0 191 0 0 47 c0 113 -52 213 -133 255 -54 27 -164 34 -228 13z
m159 -121 c27 -19 55 -71 48 -90 -4 -11 -29 -14 -110 -14 -58 0 -107 4 -110 8
-9 15 31 88 55 99 35 17 91 15 117 -3z"/>
<path d="M18086 6505 c-74 -26 -116 -72 -131 -142 l-7 -33 71 0 c52 0 71 3 71
13 0 8 10 23 23 35 46 44 167 21 167 -31 0 -34 -27 -48 -120 -63 -126 -20
-178 -46 -207 -104 -13 -24 -23 -60 -23 -80 0 -52 38 -120 80 -142 27 -15 51
-18 107 -16 62 3 78 8 118 36 l46 32 6 -30 6 -30 78 0 c79 0 92 6 64 30 -12
10 -15 46 -15 206 0 205 -5 232 -48 277 -49 52 -197 74 -286 42z m190 -370
c-9 -47 -55 -86 -110 -92 -57 -7 -86 14 -86 62 0 50 19 65 111 89 l84 21 3
-24 c2 -13 1 -38 -2 -56z"/>
<path d="M18775 6505 c-16 -9 -45 -30 -62 -48 l-33 -31 0 42 0 42 -65 0 -65 0
0 -390 0 -390 70 0 70 0 0 140 0 141 41 -33 c39 -31 46 -33 119 -33 71 0 82 3
122 30 64 46 99 120 105 227 9 140 -38 252 -123 293 -54 26 -141 30 -179 10z
m99 -130 c40 -26 59 -84 55 -167 -5 -88 -30 -132 -85 -147 -109 -30 -187 83
-154 221 18 74 54 108 115 108 25 0 56 -7 69 -15z"/>
<path d="M20366 6505 c-74 -26 -116 -72 -131 -142 l-7 -33 71 0 c52 0 71 3 71
13 0 8 10 23 23 35 46 44 167 21 167 -31 0 -34 -27 -48 -120 -63 -126 -20
-178 -46 -207 -104 -13 -24 -23 -60 -23 -80 0 -52 38 -120 80 -142 27 -15 51
-18 107 -16 62 3 78 8 118 36 l46 32 6 -30 6 -30 78 0 c79 0 92 6 64 30 -12
10 -15 46 -15 206 0 205 -5 232 -48 277 -49 52 -197 74 -286 42z m190 -370
c-9 -47 -55 -86 -110 -92 -57 -7 -86 14 -86 62 0 50 19 65 111 89 l84 21 3
-24 c2 -13 1 -38 -2 -56z"/>
<path d="M21375 6511 c-73 -18 -118 -56 -141 -118 -22 -62 -21 -63 56 -63 51
0 70 4 70 13 0 8 10 23 23 35 46 44 167 21 167 -31 0 -34 -27 -48 -120 -63
-126 -20 -178 -46 -207 -104 -13 -24 -23 -60 -23 -80 0 -52 38 -120 80 -142
27 -15 51 -18 107 -16 62 3 78 8 118 36 l46 32 6 -30 6 -30 78 0 c79 0 92 6
64 30 -12 10 -15 46 -15 206 0 205 -5 232 -48 277 -45 47 -173 70 -267 48z
m171 -376 c-9 -47 -55 -86 -110 -92 -57 -7 -86 14 -86 62 0 50 19 65 111 89
l84 21 3 -24 c2 -13 1 -38 -2 -56z"/>
<path d="M12595 6498 c-3 -7 -4 -132 -3 -278 l3 -265 73 -3 72 -3 -2 278 -3
278 -68 3 c-48 2 -69 -1 -72 -10z"/>
<path d="M13795 6498 c-3 -7 -4 -132 -3 -278 l3 -265 73 -3 72 -3 -2 278 -3
278 -68 3 c-48 2 -69 -1 -72 -10z"/>
</g>
</svg>




    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
